import React from 'react';
import ReactDOM from 'react-dom';
import './scss/main.scss'
import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

//Components import
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import Home from './components/home/Home'
import Aboutus from './components/about/Aboutus'
import Products from './components/products/Products'
import ProductDetails from './components/products-detail/ProductDetails'
import MyProfile from './components/my-profile/MyProfile'
import Favourites from './components/favourites/Favourites'
import ForgotPassword from './components/forgotpassword/ForgotPassword'
import SideBar from './components/sidebar/SideBar'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Header />
      <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/about-us">
        <Aboutus />
      </Route>
      <Route path="/products">
        <Products />
      </Route>
      <Route path="/my-profile">
        <MyProfile />
      </Route>
      <Route path="/favourites">
        <Favourites />
      </Route>
      <Route path="/product-details">
        <ProductDetails />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/sidebar">
        <SideBar />
      </Route>
      </Switch>
      <Footer />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
