import React from 'react'

//Component Import
import FavList from './FavList'
import FavReq from './FavReq'

export default function Favourites() {
    return (
        <>
           <div className="container-fluid">
                <div className="row">
                    <div className="col-md-5 p-0">
                        <FavReq /> 
                    </div>
                    <div className="col-md-7 p-0">
                        <FavList />
                    </div>
                </div>
            </div>
        </>
    )
}
