import React from 'react'

import { Form,Button,Table } from 'react-bootstrap';

import { Link} from 'react-router-dom';

import '../../scss/signforms.scss'

export default function FavList() {
    return (
        <>
            <div className="signin fav_right">
                <div className="favlist">
                    {/* List */}
                    <div className="listitem">
                        <div className="tb-row">
                            <div className="tb-cell">
                                <img src="/assets/img/fav1.jpg" className="d-inline-block align-middle" alt="product" />
                            </div>
                            <div className="tb-cell pd-left">
                                <div className="product_info">
                                    <p className="title"><a href={"/product-details"}>WMB-243-ESW-003</a></p>
                                    <p className="product_no">Beading / Embellishment, Embossing, Guipure / Chemical Lace</p>
                                    <ul className="list_ul">
                                        <li>Spring/Summer</li>
                                        <li>|</li>
                                        <li>100% Polyester</li>
                                        <li>|</li>
                                        <li>Abstract</li>
                                        <li>|</li>
                                        <li>Orange</li>
                                        <li>|</li>
                                        <li>535 GSM</li>
                                        <li>|</li>
                                        <li>115 CM</li>
                                        <li>|</li>
                                        <li>WMB Patented</li>
                                        <li>|</li>
                                        <li>New</li>
                                        <li>|</li>
                                        <li>Ecological</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="close">
                        <a href={"#"}><img src="/assets/img/close.svg" className="d-inline-block align-middle" alt="close" /></a>
                        </div>
                    </div>
                    {/* List */}
                    <div className="listitem">
                        <div className="tb-row">
                            <div className="tb-cell">
                                <img src="/assets/img/fav2.jpg" className="d-inline-block align-middle" alt="product" />
                            </div>
                            <div className="tb-cell pd-left">
                                <div className="product_info">
                                    <p className="title"><a href={"/product-details"}>WMB-243-ESW-003</a></p>
                                    <p className="product_no">Beading / Embellishment, Embossing, Guipure / Chemical Lace</p>
                                    <ul className="list_ul">
                                        <li>Spring/Summer</li>
                                        <li>|</li>
                                        <li>100% Polyester</li>
                                        <li>|</li>
                                        <li>Abstract</li>
                                        <li>|</li>
                                        <li>Orange</li>
                                        <li>|</li>
                                        <li>535 GSM</li>
                                        <li>|</li>
                                        <li>115 CM</li>
                                        <li>|</li>
                                        <li>WMB Patented</li>
                                        {/* <li>|</li>
                                        <li>New</li>
                                        <li>|</li>
                                        <li>Ecological</li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="close">
                        <a href={"#"}><img src="/assets/img/close.svg" className="d-inline-block align-middle" alt="close" /></a>
                        </div>
                    </div>
                    {/* List */}
                    <div className="listitem">
                        <div className="tb-row">
                            <div className="tb-cell">
                                <img src="/assets/img/fav3.jpg" className="d-inline-block align-middle" alt="product" />
                            </div>
                            <div className="tb-cell pd-left">
                                <div className="product_info">
                                    <p className="title"><a href={"/product-details"}>WMB-243-ESW-003</a></p>
                                    <p className="product_no">Beading / Embellishment, Embossing, Guipure / Chemical Lace</p>
                                    <ul className="list_ul">
                                        <li>Spring/Summer</li>
                                        <li>|</li>
                                        <li>100% Polyester</li>
                                        <li>|</li>
                                        <li>Abstract</li>
                                        <li>|</li>
                                        <li>Orange</li>
                                        <li>|</li>
                                        <li>535 GSM</li>
                                        {/* <li>|</li> */}
                                        {/* <li>115 CM</li>
                                        <li>|</li>
                                        <li>WMB Patented</li> */}
                                        <li>|</li>
                                        <li>New</li>
                                        {/* <li>|</li>
                                        <li>Ecological</li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="close">
                        <a href={"#"}><img src="/assets/img/close.svg" className="d-inline-block align-middle" alt="close" /></a>
                        </div>
                    </div>
                    {/* List */}
                    <div className="listitem">
                        <div className="tb-row">
                            <div className="tb-cell">
                                <img src="/assets/img/fav4.jpg" className="d-inline-block align-middle" alt="product" />
                            </div>
                            <div className="tb-cell pd-left">
                                <div className="product_info">
                                    <p className="title"><a href={"/product-details"}>WMB-243-ESW-003</a></p>
                                    <p className="product_no">Beading / Embellishment, Embossing, Guipure / Chemical Lace</p>
                                    <ul className="list_ul">
                                        <li>Spring/Summer</li>
                                        <li>|</li>
                                        <li>100% Polyester</li>
                                        <li>|</li>
                                        <li>Abstract</li>
                                        <li>|</li>
                                        <li>Orange</li>
                                        <li>|</li>
                                        <li>535 GSM</li>
                                        <li>|</li>
                                        <li>115 CM</li>
                                        {/* <li>|</li>
                                        <li>WMB Patented</li>
                                        <li>|</li>
                                        <li>New</li> */}
                                        <li>|</li>
                                        <li>Ecological</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="close">
                        <a href={"#"}><img src="/assets/img/close.svg" className="d-inline-block align-middle" alt="close" /></a>
                        </div>
                    </div>
                    {/* List */}
                    <div className="listitem">
                        <div className="tb-row">
                            <div className="tb-cell">
                                <img src="/assets/img/fav5.jpg" className="d-inline-block align-middle" alt="product" />
                            </div>
                            <div className="tb-cell pd-left">
                                <div className="product_info">
                                    <p className="title"><a href={"/product-details"}>WMB-243-ESW-003</a></p>
                                    <p className="product_no">Beading / Embellishment, Embossing, Guipure / Chemical Lace</p>
                                    <ul className="list_ul">
                                        <li>Spring/Summer</li>
                                        <li>|</li>
                                        <li>100% Polyester</li>
                                        <li>|</li>
                                        <li>Abstract</li>
                                        <li>|</li>
                                        <li>Orange</li>
                                        <li>|</li>
                                        <li>535 GSM</li>
                                        <li>|</li>
                                        <li>115 CM</li>
                                        <li>|</li>
                                        <li>WMB Patented</li>
                                        <li>|</li>
                                        <li>New</li>
                                        <li>|</li>
                                        <li>Ecological</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="close">
                        <a href={"#"}><img src="/assets/img/close.svg" className="d-inline-block align-middle" alt="close" /></a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    )
}
