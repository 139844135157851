import React from 'react'

//Component Import
import Orders from './Orders'
import Account from './Account'

export default function MyProfile() {
    return (
        <>
           <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 p-0">
                        <Orders />
                    </div>
                    <div className="col-md-6 p-0 text-center">
                        <Account /> 
                    </div>
                </div>
            </div>
        </>
    )
}
