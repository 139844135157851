import React from 'react'

import { Link } from 'react-router-dom';

import '../../scss/footer.scss'

export default function Footer() {
    return (
        <>
          <footer>
              <div className="container-fluid">
                  <div className="row">
                      <div className="col-md-6">
                          <ul className="footer_links">
                              <li>&copy;2020 Workingmenblues.</li>
                              <li><Link href="/">T&C</Link> </li>
                          </ul>
                      </div>
                      <div className="col-md-6">
                          <ul className="social_links text-right">
                              <li><a href={"https://www.facebook.com/"} target="_blank"><img src="/assets/img/facebook.svg" width="10" className="d-inline-block align-top" alt=" " /></a></li>
                              <li><a href={"https://www.instagram.com/"} target="_blank"><img src="/assets/img/instagram.svg" width="10" className="d-inline-block align-top" alt=" " /></a></li>
                              <li><a href={"https://www.linkedin.com/"} target="_blank"><img src="/assets/img/linkedin.svg" width="10" className="d-inline-block align-top" alt=" " /></a></li>
                          </ul>
                      </div>
                  </div>
              </div>
          </footer>
        </>
    )
}
