import React,{useState} from 'react'
import { Form,Button,Card,Accordion,Figure,Modal } from 'react-bootstrap';
import { Range } from 'rc-slider';
// import { Form,Button,Modal } from 'react-bootstrap';
import ProductDetails from '../products-detail/ProductDetails'

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

import '../../scss/products.scss'
import 'rc-slider/assets/index.css';

function MyVerticallyCenteredModal(props) {
    return (
      <Modal className="fixed-right product_model"
        {...props} >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <ProductDetails/>
        </Modal.Body>
      </Modal>
    );
  }

export default function Products() {
    const [modalShow, setModalShow] = React.useState(false);
    const initTimeRange = [0,50];
    const [timeRange,setTimeRange] = useState(initTimeRange);
    const initWidthRange = [0,70];
    const [widthRange,setWidthRange] = useState(initWidthRange);

    const [state, setState] = useState({
        isPaneOpen: false,
        isPaneOpenLeft: false,
      });

    return (
        <> 
        {/* <SideBar/> */}
        <SlidingPane
            closeIcon={<div>Close</div>}
            isOpen={state.isPaneOpenLeft}
            from="left"
            width="95%"
            onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            setState({ isPaneOpen: false });
            }}>
            <div><ProductDetails/></div>
        </SlidingPane>
           <section className="products_sec">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="search_box">
                                <Form>
                                    <Form.Group className="test" controlId="formBasicSearch">
                                        <Form.Control type="text" placeholder="" />
                                        <span>Search <i></i></span>
                                        <p>No Records Found</p>
                                    </Form.Group>
                                    {/* <Button className="login_btn" variant="primary" type="submit">Search</Button> */}
                                </Form>
                                <div className="filter_selected_list">
                                    <div className="selested">
                                        <span>WMB Patented</span>
                                        <span><img src="/assets/img/close_img.png" className="d-inline-block align-middle" alt="close" /></span>
                                    </div>
                                    <div className="selested">
                                        <span>Eco</span>
                                        <span><img src="/assets/img/close_img.png" className="d-inline-block align-middle" alt="close" /></span>
                                    </div>
                                    <div className="selested">
                                        <span>Orange</span>
                                        <span><img src="/assets/img/close_img.png" className="d-inline-block align-middle" alt="close" /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-50">
                        <div className="product_left filter_sec">
                        <Accordion>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                Highlight
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <ul className="filter_ul">
                                            <li className="new_badge">New</li>
                                            <li className="eco_badge">Ecological</li>
                                            <li className="wmb_badge">WMB Patented Design</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                Season
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <ul className="filter_ul_other">
                                        <li className="">Fall/winter</li>
                                        <li className="active">Spring/summer</li>
                                    </ul>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                Material
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    <ul className="filter_ul_other">
                                        <li>Acetate</li>
                                        <li>Bamboo</li>
                                        <li>Cotton</li>
                                        <li className="active">Cupro</li>
                                        <li>Ecovero Viscose</li>
                                        <li>Elasthan</li>
                                        <li>Linen</li>
                                        <li>Lyocell</li>
                                        <li>Metal</li>
                                        <li>Modal</li>
                                        <li>Polyamide (Nylon)</li>
                                        <li>Polyester</li>
                                        <li>Pu (Vegan Leather)</li>
                                        <li>Ramie</li>
                                        <li>Rayon</li>
                                        <li>Recycled</li>
                                        <li>Silk</li>
                                        <li>Spandex</li>
                                        <li>Tencel</li>
                                        <li>Tpu</li>
                                        <li>Vegan Fur</li>
                                        <li>Viscose</li>
                                        <li>Wool</li>
                                    </ul>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                Characteristics
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <ul className="filter_ul_other">
                                            <li>Beading / Embellishment </li>
                                            <li>Bonded</li>
                                            <li className="active">Broderie Anglaise / Cotton Eyelet</li>
                                            <li>Canvas</li>
                                            <li>Chambrai</li>
                                            <li>Chemical Lace</li>
                                            <li>Chiffon / Georgette</li>
                                            <li>Coating</li>
                                            <li>Cold Wool</li>
                                            <li className="active">Corduroy</li>
                                            <li>Crêpe</li>
                                            <li>Crinkle</li>
                                            <li>Dobby</li>
                                            <li>Embossing</li>
                                            <li>Embroidery</li>
                                            <li>Fil Coupé</li>
                                            <li>Flannel</li>
                                            <li>Fleece (polar)</li>
                                            <li>Flock</li>
                                            <li>Foil / Lurex</li>
                                            <li>Guipure / Chemical Lace</li>
                                            <li>Jacquard</li>
                                            <li>Jersey</li>
                                            <li>Jersey / Punto Milano</li>
                                            <li>Lace</li>
                                            <li className="active">Lace Trim</li>
                                            <li>Large Embroidery</li>
                                            <li>Liquid Sequins</li>
                                            <li>Mesh (tulle)</li>
                                            <li>Neoprene / Scuba</li>
                                            <li>Organza</li>
                                            <li>Oxford</li>
                                            <li>Perforated</li>
                                            <li>Placement (panel) Embroidery</li>
                                            <li>Plain Weave</li>
                                            <li>Pleats / Plissé</li>
                                            <li>Plumetis</li>
                                            <li>Poplin</li>
                                            <li>Print</li>
                                            <li>Relief (3d)</li>
                                            <li>Ripstop</li>
                                            <li>Satin</li>
                                            <li>Sequins / Paillettes</li>
                                            <li>Sheer</li>
                                            <li>Slub</li>
                                            <li>Smocking</li>
                                            <li>Tweed</li>
                                            <li>Twill / Gabardine</li>
                                            <li>Velvet / Velour</li>
                                            <li>Voile</li>
                                            <li>Water Repellant</li>
                                            <li>Yarn Dyed</li>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                Design
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                <ul className="filter_ul_other">
                                    <li>Abstract</li>
                                    <li>Animal</li>
                                    <li>Artistic</li>
                                    <li>Check</li>
                                    <li className="active">Dots</li>
                                    <li>Ethnic</li>
                                    <li>Floral</li>
                                    <li>Folklore</li>
                                    <li>Geometrical</li>
                                    <li>Graphic</li>
                                    <li>Grid</li>
                                    <li>Houndstooth</li>
                                    <li className="active">Large Repeat</li>
                                    <li>Plaid</li>
                                    <li>Plain</li>
                                    <li>Romantic</li>
                                    <li>Small Repeat</li>
                                    <li>Stripes</li>
                                    <li>Tartan</li>
                                    <li>Tie Dye</li>
                                    <li>Tribal</li>
                                    <li>Trim</li>
                                </ul>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="5">
                                Color
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                    <ul className="filter_ul_other">
                                        <li>Black</li>
                                        <li>Brown</li>
                                        <li className="active">Burgundy</li>
                                        <li>Gold</li>
                                        <li>Green</li>
                                        <li>Grey</li>
                                        <li>Metallic</li>
                                        <li>Multi</li>
                                        <li className="active">Neutrals</li>
                                        <li>Orange</li>
                                        <li>Pink</li>
                                        <li>Purple</li>
                                        <li>Red</li>
                                        <li>Silver</li>
                                        <li>White</li>
                                        <li>Yellow</li>
                                    </ul>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="7">
                                Weight
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="7">
                                <Card.Body>
                                    <Range min={0} max={500} step={10} value={timeRange} onChange={(value)=>{setTimeRange(value)}}/>
                                    <p className="range_p">{timeRange[1]} GSM</p>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="8">
                                Width
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="8">
                                <Card.Body>
                                    <Range min={0} max={200} step={10} value={widthRange} onChange={(value)=>{setWidthRange(value)}}/>
                                    <p className="range_p">{widthRange[1]} CM</p>
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        </div>
                        <div className="product_right">
                           <div className="row">
                                {/* Product */}
                                <div className="product_item_box plr-5">
                                   <div className="product_box">
                                        <Figure>
                                            <a onClick={() => setState({ isPaneOpenLeft: true })}>
                                                <Figure.Image className="normal_img" alt="Product" src="/assets/img/product7_zoom.jpg"/>
                                                <Figure.Image className="zoomed_img" alt="Product" src="/assets/img/product7.jpg"/>
                                            </a>
                                            <Figure.Caption>
                                                <span className="pro_id">WMB-243-ESW-003</span>
                                                <span className="favorite"><img src="/assets/img/heart-fill.png" className="d-inline-block align-middle" alt="heart" /></span>
                                                <p>Guipure / Chemical lace</p>
                                                <ul className="list_ul">
                                                    <li>100% Polyester</li>
                                                    <li>|</li>
                                                    <li>535 GSM</li>
                                                    <li>|</li>
                                                    <li>Abstract</li>
                                                    <li>|</li>
                                                    <li>Spring/Summer</li>
                                                </ul>
                                            </Figure.Caption>
                                        </Figure>
                                        <div className="pro_new_badge">New</div>
                                        <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
                                   </div>
                                </div>
                                {/* Product */}
                                <div className="product_item_box plr-5">
                                   <div className="product_box">
                                        <Figure>
                                            <a onClick={() => setState({ isPaneOpenLeft: true })}>
                                                <Figure.Image className="normal_img" alt="Product" src="/assets/img/product9_zoom.jpg"/>
                                                <Figure.Image className="zoomed_img" alt="Product" src="/assets/img/product9.jpg"/>
                                            </a>
                                            <Figure.Caption>
                                                <span className="pro_id">WMB-243-ESW-003</span>
                                                <span className="favorite"><img src="/assets/img/heart-fill.png" className="d-inline-block align-middle" alt="heart" /></span>
                                                <p>Guipure / Chemical lace</p>
                                                <ul className="list_ul">
                                                    <li>100% Polyester</li>
                                                    <li>|</li>
                                                    <li>535 GSM</li>
                                                    <li>|</li>
                                                    <li>Abstract</li>
                                                    <li>|</li>
                                                    <li>Spring/Summer</li>
                                                </ul>
                                            </Figure.Caption>
                                        </Figure>
                                        <div className="pro_new_badge eco_badge">Eco</div>
                                   </div>
                                </div>
                                {/* Product */}
                                <div className="product_item_box plr-5">
                                   <div className="product_box">
                                        <Figure>
                                            <a  onClick={() => setState({ isPaneOpenLeft: true })}>
                                                <Figure.Image className="normal_img" alt="Product" src="/assets/img/product10_zoom.jpg"/>
                                                <Figure.Image className="zoomed_img" alt="Product" src="/assets/img/product10.jpg"/>
                                            </a>
                                            <Figure.Caption>
                                                <span className="pro_id">WMB-243-ESW-003</span>
                                                <span className="favorite"><img src="/assets/img/heart.svg" className="d-inline-block align-middle" alt="heart" /></span>
                                                <p>Guipure / Chemical lace</p>
                                                <ul className="list_ul">
                                                    <li>100% Polyester</li>
                                                    <li>|</li>
                                                    <li>535 GSM</li>
                                                    <li>|</li>
                                                    <li>Abstract</li>
                                                    <li>|</li>
                                                    <li>Spring/Summer</li>
                                                </ul>
                                            </Figure.Caption>
                                        </Figure>
                                   </div>
                                </div>
                                {/* Product */}
                                <div className="product_item_box plr-5">
                                   <div className="product_box">
                                        <Figure>
                                            <a  onClick={() => setState({ isPaneOpenLeft: true })}>
                                                <Figure.Image className="normal_img" alt="Product" src="/assets/img/product11_zoom.jpg"/>
                                                <Figure.Image className="zoomed_img" alt="Product" src="/assets/img/product11.jpg"/>
                                            </a>
                                            <Figure.Caption>
                                                <span className="pro_id">WMB-243-ESW-003</span>
                                                <span className="favorite"><img src="/assets/img/heart.svg" className="d-inline-block align-middle" alt="heart" /></span>
                                                <p>Guipure / Chemical lace</p>
                                                <ul className="list_ul">
                                                    <li>100% Polyester</li>
                                                    <li>|</li>
                                                    <li>535 GSM</li>
                                                    <li>|</li>
                                                    <li>Abstract</li>
                                                    <li>|</li>
                                                    <li>Spring/Summer</li>
                                                </ul>
                                            </Figure.Caption>
                                        </Figure>
                                   </div>
                                </div>
                                {/* Product */}
                                <div className="product_item_box plr-5">
                                   <div className="product_box">
                                        <Figure>
                                            <a onClick={() => setState({ isPaneOpenLeft: true })}>
                                                <Figure.Image className="normal_img" alt="Product" src="/assets/img/product12_zoom.jpg"/>
                                                <Figure.Image className="zoomed_img" alt="Product" src="/assets/img/product12.jpg"/>
                                            </a>
                                            <Figure.Caption>
                                                <span className="pro_id">WMB-243-ESW-003</span>
                                                <span className="favorite"><img src="/assets/img/heart.svg" className="d-inline-block align-middle" alt="heart" /></span>
                                                <p>Guipure / Chemical lace</p>
                                                <ul className="list_ul">
                                                    <li>100% Polyester</li>
                                                    <li>|</li>
                                                    <li>535 GSM</li>
                                                    <li>|</li>
                                                    <li>Abstract</li>
                                                    <li>|</li>
                                                    <li>Spring/Summer</li>
                                                </ul>
                                            </Figure.Caption>
                                        </Figure>
                                   </div>
                                </div>
                                {/* Product */}
                                <div className="product_item_box plr-5">
                                   <div className="product_box">
                                        <Figure>
                                            <a onClick={() => setState({ isPaneOpenLeft: true })}>
                                                <Figure.Image className="normal_img" alt="Product" src="/assets/img/product1.jpg"/>
                                                <Figure.Image className="zoomed_img" alt="Product" src="/assets/img/product1.jpg"/>
                                            </a>
                                            <Figure.Caption>
                                                <span className="pro_id">WMB-243-ESW-003</span>
                                                <span className="favorite"><img src="/assets/img/heart.svg" className="d-inline-block align-middle" alt="heart" /></span>
                                                <p>Guipure / Chemical lace</p>
                                                <ul className="list_ul">
                                                    <li>100% Polyester</li>
                                                    <li>|</li>
                                                    <li>535 GSM</li>
                                                    <li>|</li>
                                                    <li>Abstract</li>
                                                    <li>|</li>
                                                    <li>Spring/Summer</li>
                                                </ul>
                                            </Figure.Caption>
                                        </Figure>
                                   </div>
                                </div>
                                {/* Product */}
                                <div className="product_item_box plr-5">
                                   <div className="product_box">
                                        <Figure>
                                            <a onClick={() => setState({ isPaneOpenLeft: true })}>
                                                <Figure.Image className="normal_img" alt="Product" src="/assets/img/product2.png"/>
                                                <Figure.Image className="zoomed_img" alt="Product" src="/assets/img/product2.png"/>
                                            </a>
                                            <Figure.Caption>
                                                <span className="pro_id">WMB-243-ESW-003</span>
                                                <span className="favorite"><img src="/assets/img/heart.svg" className="d-inline-block align-middle" alt="heart" /></span>
                                                <p>Guipure / Chemical lace</p>
                                                <ul className="list_ul">
                                                    <li>100% Polyester</li>
                                                    <li>|</li>
                                                    <li>535 GSM</li>
                                                    <li>|</li>
                                                    <li>Abstract</li>
                                                    <li>|</li>
                                                    <li>Spring/Summer</li>
                                                </ul>
                                            </Figure.Caption>
                                        </Figure>
                                   </div>
                                </div>
                                {/* Product */}
                                <div className="product_item_box plr-5">
                                   <div className="product_box">
                                        <Figure>
                                            <a onClick={() => setState({ isPaneOpenLeft: true })}>
                                                <Figure.Image className="normal_img" alt="Product" src="/assets/img/product3.png"/>
                                                <Figure.Image className="zoomed_img" alt="Product" src="/assets/img/product3.png"/>
                                            </a>
                                            <Figure.Caption>
                                                <span className="pro_id">WMB-243-ESW-003</span>
                                                <span className="favorite"><img src="/assets/img/heart.svg" className="d-inline-block align-middle" alt="heart" /></span>
                                                <p>Guipure / Chemical lace</p>
                                                <ul className="list_ul">
                                                    <li>100% Polyester</li>
                                                    <li>|</li>
                                                    <li>535 GSM</li>
                                                    <li>|</li>
                                                    <li>Abstract</li>
                                                    <li>|</li>
                                                    <li>Spring/Summer</li>
                                                </ul>
                                            </Figure.Caption>
                                        </Figure>
                                        <div className="pro_new_badge">New</div>
                                        <div className="pro_new_badge eco_badge bd_top">Eco</div>
                                   </div>
                                </div>
                                {/* Product */}
                                <div className="product_item_box plr-5">
                                   <div className="product_box">
                                        <Figure>
                                            <a onClick={() => setState({ isPaneOpenLeft: true })}>
                                                <Figure.Image className="normal_img" alt="Product" src="/assets/img/product4.png"/>
                                                <Figure.Image className="zoomed_img" alt="Product" src="/assets/img/product4.png"/>
                                            </a>
                                            <Figure.Caption>
                                                <span className="pro_id">WMB-243-ESW-003</span>
                                                <span className="favorite"><img src="/assets/img/heart.svg" className="d-inline-block align-middle" alt="heart" /></span>
                                                <p>Guipure / Chemical lace</p>
                                                <ul className="list_ul">
                                                    <li>100% Polyester</li>
                                                    <li>|</li>
                                                    <li>535 GSM</li>
                                                    <li>|</li>
                                                    <li>Abstract</li>
                                                    <li>|</li>
                                                    <li>Spring/Summer</li>
                                                </ul>
                                            </Figure.Caption>
                                        </Figure>
                                   </div>
                                </div>
                                {/* Product */}
                                <div className="product_item_box plr-5">
                                   <div className="product_box">
                                        <Figure>
                                            <a onClick={() => setState({ isPaneOpenLeft: true })}>
                                                <Figure.Image className="normal_img" alt="Product" src="/assets/img/product5.png"/>
                                                <Figure.Image className="zoomed_img" alt="Product" src="/assets/img/product5.png"/>
                                            </a>
                                            <Figure.Caption>
                                                <span className="pro_id">WMB-243-ESW-003</span>
                                                <span className="favorite"><img src="/assets/img/heart.svg" className="d-inline-block align-middle" alt="heart" /></span>
                                                <p>Guipure / Chemical Lace</p>
                                                <ul className="list_ul">
                                                    <li>100% Polyester</li>
                                                    <li>|</li>
                                                    <li>535 GSM</li>
                                                    <li>|</li>
                                                    <li>Abstract</li>
                                                    <li>|</li>
                                                    <li>Spring/Summer</li>
                                                </ul>
                                            </Figure.Caption>
                                        </Figure>
                                   </div>
                                </div>
                                {/* Product */}
                                <div className="product_item_box plr-5">
                                   <div className="product_box">
                                        <Figure>
                                            <a onClick={() => setModalShow(true)}>
                                                <Figure.Image className="normal_img" alt="Product" src="/assets/img/product6.png"/>
                                                <Figure.Image className="zoomed_img" alt="Product" src="/assets/img/product6.png"/>
                                            </a>
                                            <Figure.Caption>
                                                <span className="pro_id">WMB-243-ESW-003</span>
                                                <span className="favorite"><img src="/assets/img/heart.svg" className="d-inline-block align-middle" alt="heart" /></span>
                                                <p>Guipure / Chemical Lace</p>
                                                <ul className="list_ul">
                                                    <li>100% Polyester</li>
                                                    <li>|</li>
                                                    <li>535 GSM</li>
                                                    <li>|</li>
                                                    <li>Abstract</li>
                                                    <li>|</li>
                                                    <li>Spring/Summer</li>
                                                </ul>
                                            </Figure.Caption>
                                        </Figure>
                                        <div className="pro_new_badge wmb_badge">WMB</div>
                                   </div>
                                </div>
                           </div>
                        </div>
                    </div>
                </div>
           </section>
        </>
    )
}
