import React from 'react'

import { Form,Button,Card,Accordion,Figure } from 'react-bootstrap';
import {
    Magnifier,
    GlassMagnifier,
    SideBySideMagnifier,
    PictureInPictureMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION
  } from "react-image-magnifiers";

import '../../scss/products.scss'

export default function ProductDetails() {
    return (
        <>  
            {/* <Header/> */}
            
            <div className="overlay">
                <section className="products_sec product_details_sec">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="product_info_middle mt-50">
                                <div className="imgthumb">
                                    <Figure>
                                        <Figure.Image alt="Product" src="/assets/img/actual_img.jpg"/>
                                    </Figure>
                                    <Figure>
                                        <Figure.Image alt="Product" src="/assets/img/product7_zoom.jpg"/>
                                    </Figure>
                                </div>
                                <div className="zoomimg">
                                {<SideBySideMagnifier 
                                    imageSrc="/assets/img/actual_img.jpg" className="actual_img"
                                    imageAlt="Zoom Image"
                                    largeImageSrc="/assets/img/product7_zoom.jpg" // Optional
                                    magnifierSize="50"
                                /> }
                                
                                </div>
                            </div>
                            <div className="product_info_right mt-50">
                                <div className="product_div">
                                    <ul className="product_detail_ul">
                                        <li>Article: WMB-243-ESW-003</li>
                                        <li className="right_info"><u><a href={"/assets/img/favourites_print-out_pdf.jpg"} target="_blank">Print</a></u></li>
                                    </ul>
                                    <div className="space-50"></div>
                                    <ul className="product_detail">
                                        <li>Season</li>
                                        <li className="right_info">Spring/Summer</li>
                                    </ul>
                                    <ul className="product_detail">
                                        <li>Composition</li>
                                        <li className="right_info">100% Polyester</li>
                                    </ul>
                                    <ul className="product_detail characteristic_ul">
                                        <li>Characteristics</li>
                                        <li className="right_info">Beading / Embellishment, Embossing, Guipure / Chemical Lace</li>
                                    </ul>
                                    {/* <ul className="product_detail">
                                        <li>Construction</li>
                                        <li className="right_info">Chemical Lace</li>
                                    </ul>
                                    <ul className="product_detail">
                                        <li>Technique</li>
                                        <li className="right_info">Guipure</li>
                                    </ul> */}
                                    <ul className="product_detail">
                                        <li>Design</li>
                                        <li className="right_info">Abstract</li>
                                    </ul>
                                    <ul className="product_detail">
                                        <li>Color</li>
                                        <li className="right_info">Orange, Green</li>
                                    </ul>
                                    <ul className="product_detail">
                                        <li>Weight</li>
                                        <li className="right_info">535 GSM</li>
                                    </ul>
                                    <ul className="product_detail">
                                        <li>Width</li>
                                        <li className="right_info">115 CM</li>
                                    </ul>
                                    <ul className="product_detail">
                                        <li>WMB Patented design</li>
                                        <li className="right_info">Yes</li>
                                    </ul>
                                    <ul className="product_detail">
                                        <li>Ecological</li>
                                        <li className="right_info">Yes</li>
                                    </ul>
                                    <ul className="product_detail">
                                        <li>New</li>
                                        <li className="right_info">Yes</li>
                                    </ul>
                                    <div className="fav_next">
                                        <div className="left">
                                            <a href={"#"}><p>Add to my Favourites</p>
                                            <span className="favorite"><img src="/assets/img/heart.svg" className="d-inline-block align-middle" alt="heart" /></span></a>
                                        </div>
                                        <div className="right">
                                            <a href={"/favourites"}> <p>Go to Favourites</p>
                                            <span className="favorite"><img src="/assets/img/arrow.svg" className="d-inline-block align-middle" alt="heart" /></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="close_btn">
                                <a href={"/products"}>Close</a>
                            </div> */}
                        </div>
                    </div>
            </section>
            </div>
            {/* <Footer/> */}
        </>
    )
}
