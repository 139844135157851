import React from 'react'

import { Form,Button,Modal } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import '../../scss/signforms.scss'

function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div className="signin_form">
                <p className="signin_p">REQUEST</p>
                <p>Once your request is in, we will contact you with further information. Do you want to request your selection?</p>
                <Form>
                    <a href={"/products"} className="login_btn login_link" variant="primary" type="submit">Yes</a>
                </Form>
                <div className="or">
                    Or
                </div>
                <Link href="/" className="forgot_a">Not yet, continue browsing</Link> 
            </div>
        </Modal.Body>
      </Modal>
    );
  }

export default function FavReq() {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            <div className="signin signup fav_left">
                <div className="signin_form my_account_div">
                    <p className="signin_p">FAVOURITES</p>
                    <p>Request your favourite fabrics.</p>
                    {/* <Button className="login_btn" variant="primary" type="submit">Print</Button> */}
                    <a href={"http://fabricsdesign.webstaging.info/pdf/"} className="login_btn login_link" target="_blank">Print</a>
                    {/* <Button className="login_btn" variant="primary" type="submit">Request</Button> */}
                    <Button className="login_btn" variant="primary" onClick={() => setModalShow(true)}>Request</Button>
                    <div className="fav_class">
                        <div className="or">
                            Or
                        </div>
                        <a href={"/products"} className="forgot_a">Continue browsing</a>
                    </div>
                </div> 
                <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />            
            </div>
        </>
    )
}
