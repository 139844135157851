import React from 'react'
import { Form,Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../scss/signforms.scss'

export default function ForgotPassword() {
    return (
        <>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 p-0">
                    <div className="signin">
                        <div className="signin_form">
                            <p className="signin_p">Forgot Password</p>
                            <p>Please provide your Email ID</p>
                            <Form>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="email" placeholder="Email" />
                                </Form.Group>
                                <a href={"/products"} className="login_btn login_link" variant="primary" type="submit">Submit</a>
                                <div className="extra_space"></div>
                                <p className="signin_p">Change Password</p>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Control type="password" placeholder="New Password" />
                                    <Form.Control type="password" placeholder="Confirm New Password" />
                                </Form.Group>
                                {/* <Button className="login_btn" variant="primary" type="submit">Log in</Button> */}
                                <a href={"/products"} className="login_btn login_link" variant="primary" type="submit">Change Password</a>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
