import React from 'react'

//Component Import
import Signin from './Signin'
import Signup from './Signup'
export default function Home() {
    return (
        <>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6 p-0">
                    <Signin />
                </div>
                <div className="col-md-6 p-0">
                    <Signup /> 
                </div>
            </div>
        </div>
        </>
    )
}
