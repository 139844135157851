import React from 'react'

import { Form,Button } from 'react-bootstrap';

import '../../scss/signforms.scss'

export default function Signup() {
    return (
        <>
            <div className="signin signup">
                <div className="signin_form my_account_div">
                    <p className="signin_p">My Information</p>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="email" placeholder="Email" />
                        </Form.Group>
                        <Form.Group controlId="formBasicName">
                            <Form.Control type="text" placeholder="Name" />
                        </Form.Group>
                        <Form.Group controlId="formBasicCompany">
                            <Form.Control type="text" placeholder="Company" />
                        </Form.Group>
                        <Form.Group controlId="formBasicPosition">
                            <Form.Control type="text" placeholder="Position" />
                        </Form.Group>
                        <Button className="login_btn" variant="primary" type="submit">Save Changes</Button>
                    </Form>
                </div>
                <div className="signin_form my_account_div">
                    <p className="signin_p">My Account Settings</p>
                    <Form>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Control type="password" placeholder="Current Password" />
                        </Form.Group>
                        <Form.Group controlId="formBasicNewPassword">
                            <Form.Control type="password" placeholder="New Password" />
                        </Form.Group>
                        <Form.Group controlId="formBasicConfirmNewPassword">
                            <Form.Control type="password" placeholder="Confirm New Password" />
                        </Form.Group>
                        <div className="space"></div>
                        <Button className="login_btn" variant="primary" type="submit">Change password</Button>
                    </Form>
                </div>
                
            </div>
        </>
    )
}
