import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import { Navbar,NavDropdown,Nav } from 'react-bootstrap';

import '../../scss/header.scss'

export default function Header() {
    return (
        <>
           <section className="top_header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Navbar expand="lg" sticky="bottom">
                                <Navbar.Brand>
                                    <Nav.Link className="logo" href="/"><img src="/assets/img/logo.svg" className="d-inline-block align-middle" alt=" "></img></Nav.Link>
                                    <Nav.Link className="logo new_logo" href="/products"><span>DIGITAL FABRIC LIBRARY</span></Nav.Link>
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                                    <Nav className="mr-sm-2">
                                        <Nav.Link href="/favourites">Favourites</Nav.Link>
                                        <Nav.Link href="/my-profile">My Account</Nav.Link>
                                        <Nav.Link href="">| <img src="/assets/img/power-button.svg" className="d-inline-block align-middle logout_btn"  alt="Logout" title="Logout"></img></Nav.Link>
                                        <Nav.Link href="https://workingmenblues.com/" target="_blank">back to WORKINGMENBLUES</Nav.Link>
                                        {/* <Nav.Link href="/products" className="nav_btn">DIGITAL FABRIC LIBRARY</Nav.Link> */}
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </div>
                    </div>
                </div>
           </section>
        </>
    )
}
