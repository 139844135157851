import React from 'react'

import { Form,Button,Modal } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import '../../scss/signforms.scss'

function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div className="signin_form">
                <p className="signin_p">THANK YOU</p>
                <p>Thank you for registering with us! You will receive an email once the admin approves.</p>
                <Form>
                    {/* <Button className="login_btn" variant="primary">Close</Button> */}
                    <a href={"/"} className="login_btn login_link" variant="primary" type="submit">Close</a>
                </Form>
            </div>
        </Modal.Body>
      </Modal>
    );
  }

export default function Signup() {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            <div className="signin signup">
                <div className="signin_form">
                    <p className="signin_p">Sign up</p>
                    <p>Are you a brand / designer / buyer?</p>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="email" placeholder="Email" />
                        </Form.Group>
                        <Form.Group controlId="formBasicName">
                            <Form.Control type="text" placeholder="Name" />
                        </Form.Group>
                        <Form.Group controlId="formBasicCompany">
                            <Form.Control type="text" placeholder="Company" />
                        </Form.Group>
                        <Form.Group controlId="formBasicPosition">
                            <Form.Control type="text" placeholder="Position" />
                        </Form.Group>
                        <Button className="login_btn" variant="primary" onClick={() => setModalShow(true)}>Request</Button>
                    </Form>
                    <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
                </div>
            </div>
        </>
    )
}
