import React from 'react'

import { Form,Button,Table } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import '../../scss/signforms.scss'

export default function Signin() {
    return (
        <>
            <div className="signin">
                <div className="orders_box">
                    <p className="signin_p">My Orders</p>
                    <Table size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Order Date</th>
                                <th>Status</th>
                                <th>View / Print</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>20.03.2020</td>
                                <td>Successfully delivered</td>
                                <td>
                                    <ul>
                                        <li><a href={"http://fabricsdesign.webstaging.info/pdf/"} target="_blank"><img src="/assets/img/view.png" className="d-inline-block align-middle" alt="view" /></a></li>
                                        <li><a href={"http://fabricsdesign.webstaging.info/pdf/"} target="_blank"><img src="/assets/img/print.png" className="d-inline-block align-middle" alt="print" /></a></li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>20.03.2020</td>
                                <td>Successfully delivered</td>
                                <td>
                                    <ul>
                                        <li><a href={"http://fabricsdesign.webstaging.info/pdf/"} target="_blank"><img src="/assets/img/view.png" className="d-inline-block align-middle" alt="view" /></a></li>
                                        <li><a href={"http://fabricsdesign.webstaging.info/pdf/"} target="_blank"><img src="/assets/img/print.png" className="d-inline-block align-middle" alt="print" /></a></li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>20.03.2020</td>
                                <td>Successfully delivered</td>
                                <td>
                                    <ul>
                                        <li><a href={"http://fabricsdesign.webstaging.info/pdf"} target="_blank"><img src="/assets/img/view.png" className="d-inline-block align-middle" alt="view" /></a></li>
                                        <li><a href={"http://fabricsdesign.webstaging.info/pdf"} target="_blank"><img src="/assets/img/print.png" className="d-inline-block align-middle" alt="print" /></a></li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>20.03.2020</td>
                                <td>Successfully delivered</td>
                                <td>
                                    <ul>
                                        <li><a href={"http://fabricsdesign.webstaging.info/pdf"} target="_blank"><img src="/assets/img/view.png" className="d-inline-block align-middle" alt="view" /></a></li>
                                        <li><a href={"http://fabricsdesign.webstaging.info/pdf"} target="_blank"><img src="/assets/img/print.png" className="d-inline-block align-middle" alt="print" /></a></li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>20.03.2020</td>
                                <td>Successfully delivered</td>
                                <td>
                                    <ul>
                                        <li><a href={"http://fabricsdesign.webstaging.info/pdf"} target="_blank"><img src="/assets/img/view.png" className="d-inline-block align-middle" alt="view" /></a></li>
                                        <li><a href={"http://fabricsdesign.webstaging.info/pdf"} target="_blank"><img src="/assets/img/print.png" className="d-inline-block align-middle" alt="print" /></a></li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>20.03.2020</td>
                                <td>Successfully delivered</td>
                                <td>
                                    <ul>
                                        <li><a href={"http://fabricsdesign.webstaging.info/pdf"} target="_blank"><img src="/assets/img/view.png" className="d-inline-block align-middle" alt="view" /></a></li>
                                        <li><a href={"http://fabricsdesign.webstaging.info/pdf"} target="_blank"><img src="/assets/img/print.png" className="d-inline-block align-middle" alt="print" /></a></li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <ul className="pagination">
                                <li className="page-item">
                                    <a href={"#"} target="_blank">Previous</a>
                                </li>
                                <li className="page-item">
                                    <a href={"#"} className="active" target="_blank">1</a>
                                </li>
                                <li className="page-item active">
                                    <a href={"#"} target="_blank">2</a>
                                </li>
                                <li className="page-item active">
                                    <a href={"#"} target="_blank">3</a>
                                </li>
                                <li className="page-item active">
                                    <a href={"#"} target="_blank">4</a>
                                </li>
                                <li className="page-item">
                                    <a href={"#"} target="_blank">Next</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
