import React from 'react'

import { Form,Button } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import '../../scss/signforms.scss'

export default function Signin() {
    return (
        <>
            <div className="signin home_sign">
                <div className="signin_form">
                    <p className="signin_p">Sign in</p>
                    <p>Welcome back!</p>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control type="email" placeholder="Email" />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Control type="password" placeholder="Password" />
                        </Form.Group>
                        {/* <Button className="login_btn" variant="primary" type="submit">Log in</Button> */}
                        <a href={"/products"} className="login_btn login_link" variant="primary" type="submit">Log in</a>
                    </Form>
                    <div className="or">
                        Or
                    </div>
                    <a href={"/forgot-password"} className="forgot_a">Forgot password</a>
                </div>
            </div>
        </>
    )
}
